<template>
  <div class="bg-pages-components" v-if="isShow">
    <span class="common-page prev" @click="changePage(1)" :class="myPage <= 1 ? 'disabled' : ''"><i class="el-icon-arrow-left"></i>{{$t('pages.prev')}}</span><span class="common-page next" @click="changePage(2)" :class="isLast ? 'disabled' : ''">{{$t('pages.next')}}<i class="el-icon-arrow-right"></i></span>
  </div>
</template>

<script>
export default {
  name:'bgPage',
  props: {
    currentPage:{
        type:Number,
        default:1
    },
    pageSize:{
       type:Number,
       default:10
    },
    total:{
        type:Number,
        default:-1,
    },
    /**
     * 是否有下一页
     */
    nextFlag:{
       type:Boolean,
       default:false
    }
  },
  data() {
    return {
      selfPrev: 1,
      selfNext: 2
    }
  },
  computed:{
     isLast(){
         //判断当前页码 展示条数 大于等于 total 
         // 传入 total
         if(this.total != -1){
            let showTotal = Number(this.pageSize) * Number(this.myPage);
            if(showTotal >= this.total){
                return true;
            }
         } else {
           // 传入 nextFlag 
           return !this.nextFlag;
         }
        
         return false;
     },
     isShow(){
       if(this.total > this.pageSize){
           return true;
       }

       if(this.myPage > 1 ){
         return true;
       }

       if(this.myPage ===1 && this.nextFlag ){
         return true;
       }

       return false;
     },
     myPage:{
       get(){
         return this.currentPage;
       },
       set(val){
         this.$emit('update:currentPage',val);
       }
     }
  },
  methods: {
    changePage(val) {
      let prepage = this.myPage
      if(val === 1) {
        if(this.currentPage == 1){
            return;
        }
        this.myPage = this.myPage -1;     
      } else {
        if(this.isLast){
            return;
        }
        this.myPage = this.myPage + 1;
      }
      this.$nextTick(()=>{
         this.$emit('current-change', this.myPage,prepage)
      })      
    }
  }
}
</script>

<style lang="less" scoped>
.bg-pages-components{
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top:30px;
  .common-page{
    display: inline-block;
    height: 26px;
    color: @gray-P1;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    padding: 0 15px 0 10px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    transition: all .3s;
    &:last-child{
      margin-left: 8px;
    }
    &.next{
      padding:0 10px 0 15px;
    }
    &.disabled{
      color: #c0c4cc;
      border: 1px solid #c0c4cc;
      cursor: not-allowed;
      &:hover{
        color: #c0c4cc;
        border: 1px solid #c0c4cc;
        cursor: not-allowed;
      }
    }
    &:hover{
      color: @yellow-P2;
      border: 1px solid @yellow-P2;
      cursor: pointer;
    }
  }
}
</style>
