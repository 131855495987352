<template>
  <div class="bg-component-input bg-component-input-y">
    <div class="bg-input-label" v-if="$slots.label">
      <slot name="label"></slot>
    </div>
    <div class="bg-input__wrap" :class="{'focus':inputfous}" @mouseover="mouseOverFn" @mouseout="inputfous = false">
      <div class="bg-input__pre">
        <slot name="prepend"></slot>
        <slot name="prefix"></slot>
      </div>
      <input class="input" :style="myStyle" v-bind="$attrs" v-on="mylisteners" />
      <div class="bg-input__append-wrap">
        <p class="bg-input__suffix"><slot name="suffix"></slot></p>
        <p class="bg-input__append"><slot name="append"></slot></p>
      </div>
      <!-- <div class="focusline" :class="{ focus: inputfous }"></div> -->
      <transition name="moveshow">
        <div v-if="error" class="error">{{ error }}</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "bgInput",
  inheritAttrs: false,
  data() {
    return {
      inputfous: false
    };
  },
  props: {
    error: {
      type: String,
      default: ""
    },
    myStyle: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    mylisteners: function() {
      let that = this;
      return {
        ...that.$listeners,
        input(event) {
          that.$emit("update:error", "");
          that.$emit("input", event.target.value);
        },
        blur() {
          that.inputfous = false;
          that.$emit("blur");
        },
        focus() {
          that.inputfous = true;
          that.$emit("focus");
        }
      };
    }
  },
  mounted() {},
  methods: {
    inputBlur() {
      this.$emit("blur", this.iptStr);
    },
    mouseOverFn() {
      if(this.$attrs['disabled']) {
        return;
      }
      this.inputfous = true;
    },
  }
};
</script>

<style lang="less">
.bg-component-input {
  position: relative;
  width: 362px;
  &.bg-component-input-y {
    width: 100%;
  }
  .bg-input-label {
    font-size: 14px;
    color: @text-black;
    line-height: 14px;
    margin-bottom: 16px;
    .link-text{
      color: red;
    }
  }
  .bg-input__wrap {
    position: relative;
    display: inline-block;
    // border-bottom: 1px solid #dee1e4;
    // transition: 0.35s ease all;
    display: flex;
    align-items: center;
    background: @white-input-color ;
    height: 38px;
    &.focus {
      background: @theme-white;
      border-color: 1px solid @yellow-P3;
    }
    .focusline {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      border-bottom: 1px solid #4470dc;
      transition: 0.35s ease all;
      &.focus {
        width: 100%;
        //border-color: #1465ff;
      }
    }
    .block-prefix{
      height: 50px;
      line-height: 50px;
      background: #F5F5F5;
      padding-left: 6px;
    }

    .input {
      flex: 1;
      height: 100%;
      font-size: 14px;
      border: none;
      outline: none;
      transition: 0.35s ease all;
      padding-bottom: 12px;
      padding: 6px 15px;
      background: #F5F5F5;
      &:disabled{
        background-color: #fff;
      }
    }
    .bg-input__append-wrap {
      display: flex;
      height: 50px;
      line-height: 50px;
      background:#F5F5F5 ;
      padding-right: 17px;
      .bg-input__suffix {
        font-size: 14px;
        color: @gray-P1;
        letter-spacing: 0;
        margin-right:12px;
      }
      .bg-input__append {
        font-size: 14px;
        color: @yellow-P3 ;
        p.el-icon-arrow-down{
          margin-left: -30;
        }
      }

    }
    .error {
      position: absolute;
      left: 0;
      bottom: -18px;
      font-size: 12px;
      color: @red-color;
      line-height: 14px;
    }
  }
  // WebKit browsers
  input::-webkit-input-placeholder {
    font-size: 14px;
    color: @gray-P1 !important;
    .medium-font();
  }

  // Mozilla Firefox 4 to 18
  input:-moz-placeholder {
    font-size: 14px;
    color: @gray-P1 !important;
    .medium-font();
  }

  // Mozilla Firefox 19+
  input::-moz-placeholder {
    font-size: 14px;
    color: @gray-P1 !important;
    .medium-font();
  }

  // Internet Explorer 10+
  input:-ms-input-placeholder {
    font-size: 14px;
    color: @gray-P1 !important;
    .medium-font();
  }
}
</style>
