const common = {
  emailReg: /^([a-zA-Z0-9_\-\.\+]+)\@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,100}|[0-9]{1,3})(\]?)$/,
  telReg: /(^1[3|4|6|5|7|8|9]\d{9}$)|(^09\d{8}$)/,
  pwdReg: /^(?![0-9]+$)(?![a-zA-Z]+$)[\S]{6,32}$/,
  // 坑1：特殊字符中，不包含'(单引号) "(双引号) \(反斜杠) 三个，不要问为什么
  newPwdReg: /^(?![\d]+$)(?![a-zA-Z]+$)(?![~`!@#\$%\^&\*\(\)_\-\+=\{\}\[\]\|;:,<>\.\?\/]+$)[\da-zA-Z~`!@#\$%\^&\*\(\)_\-\+=\{\}\[\]\|;:,<>\.\?\/]{8,32}$/,
  codeReg: /^[0-9]{6}$/,
  idnumber: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
  forbiddenCountryCode: ['CN', 'HK', 'CU', "IR", "KP", "SD", "MY", "SY", "BD", "BO", "EC", "KG"],
  telegramReg: /^[0-9a-zA-Z]{5,30}$/,
  /**
   * 验证国际手机号码
   * 暂时只对中国进行校验
   * @param {string} str 手机号码
   * @param {Number} code 国家区号
   */
  checkI18nPhone(str, code) {
    code = Number(code)
    if (str) {
      if (code === 86) {
        return this.telReg.test(str)
      }
      // else if (code === 82) {
      //   let reg = /^01\d{7,9}$/
      //   return reg.test(str)
      // }
      else {
        return /^\d+$/.test(str)
      }
    } else {
      return false
    }
  },

  /**
   *
   * @param {string}} pwdStr 密码等级得分
   */
  gradefn(pwdStr) {
    let grade = 0
    let passleng = pwdStr.length
    let passval = pwdStr
    // 长度判断
    if (passleng <= 6) {
      grade += 0
    } else if (passleng >= 8) {
      grade += 25
    } else {
      grade += 10
    }
    // 字母判断
    if (!passval.match(/[a-z]/i)) {
      grade += 0
    } else if (passval.match(/[a-z]/) && passval.match(/[A-Z]/)) {
      grade += 20
    } else {
      grade += 10
    }
    // 数字判断
    if (!passval.match(/[0-9]/)) {
      grade += 0
    } else if ((passval.match(/[0-9]/g)).length > 3) {
      grade += 20
    } else {
      grade += 10
    }
    // 符号
    if (!passval.match(/\W/)) {
      grade += 0
    } else if ((passval.match(/\W/g)).length > 1) {
      grade += 25
    } else {
      grade += 10
    }
    // 奖励
    if (!passval.match(/[0-9]/) || !passval.match(/[a-z]/i)) {
      grade += 0
    } else if (!passval.match(/\W/)) {
      grade += 0
    } else if (!passval.match(/[a-z]/) || !passval.match(/[A-Z]/)) {
      grade += 2
    } else {
      grade += 5
    }
    // 连续或重复
    // 三次重复字母或数字
    let pas = /(\w)*(\w)\2{2}(\w)*/g
    // 连续数字
    let lianshu = /(0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){2}\d/

    if (passval.match(pas) || passval.match(lianshu)) {
      // grade -= 10 // 2020-09-10 密码等级修改，连续或重复先不扣分，不然显示不了
    }
    return grade
  },

  /**
   *
   * @param {string} pwdStr 密码等级判定
   */
  passwordLevel(pwdStr) {
    let score = this.gradefn(pwdStr)
    let level = 0
    if (score >= 20 && score < 52 && this.pwdReg.test(pwdStr)) {
      level = 1 // 弱 low
    } else if (score < 82 && score >= 52 && this.pwdReg.test(pwdStr)) {
      level = 2 // 中 medium
    } else if (score >= 82 && this.pwdReg.test(pwdStr)) {
      level = 3 // 强 high
    }
    return level
  },

  /**
   *
   * @param {string}} pwdStr 密码等级得分
   */
  newGradeFn(pwdStr) {
    let grade = 0;
    let passLen = pwdStr.length;
    let passVal = pwdStr;

    // 长度判断
    if (passLen == 8) {
      grade += 5;
    } else if(passLen == 9 || passLen == 10) {
      grade += 10;
    } else if(passLen > 10) {
      grade += 20;
    }

    // 字母判断
    if(passVal.match(/[a-z]/) && passVal.match(/[A-Z]/)) {
      grade += 20;
    } else if (passVal.match(/[a-z]/i)) {
      grade += 10;
    }

    // 数字判断
    if (!passVal.match(/[0-9]/)) {
      grade += 0;
    } else if ((passVal.match(/[0-9]/g)).length >= 3) {
      grade += 20;
    } else if((passVal.match(/[0-9]/g)).length < 3) {
      grade += 10;
    }

    // 符号
    if (!passVal.match(/\W/)) {
      grade += 0;
    } else if ((passVal.match(/\W/g)).length >= 3) {
      grade += 25;
    } else if ((passVal.match(/\W/g)).length < 3) {
      grade += 10;
    }

    // 奖励
    if(passVal.match(/[A-Z]/) && passVal.match(/[a-z]/) && passVal.match(/\W/) && passVal.match(/[0-9]/)) {
      grade += 15;
    } else if(passVal.match(/[a-zA-Z]/i) && passVal.match(/\W/) && passVal.match(/[0-9]/)) {
      grade += 10;
    } else if (passVal.match(/[0-9]/) && passVal.match(/[a-z]/)) {
      grade += 5;
    } else if(passVal.match(/[0-9]/) && passVal.match(/\W/)) {
      grade += 5;
    } else if(passVal.match(/[a-z]/) && passVal.match(/\W/)) {
      grade += 5;
    } else if(passVal.match(/[A-Z]/) && passVal.match(/\W/)) {
      grade += 5;
    }

    // 连续或重复
    // 四次重复字母或数字
    let strReduceReg = /(\w)*(\w)\2{3}(\w)*/g;
    // 连续数字
    let NumReduceReg = /(0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){3}\d/;
    if (passVal.match(strReduceReg) || passVal.match(NumReduceReg)) {
      grade -= 10;
    }

    return grade;
  },

  /**
   *
   * @param {string} pwdStr 密码等级判定
   */
  newPasswordLevel(pwdStr) {
    let score = this.newGradeFn(pwdStr);
    let level = 0;
    if (score < 50 && this.pwdReg.test(pwdStr)) {
      level = 1; // 弱 low
    } else if (80 > score && score >= 50 && this.pwdReg.test(pwdStr)) {
      level = 2; // 中 medium
    } else if (score >= 80 && this.pwdReg.test(pwdStr)) {
      level = 3; // 强 high
    }
    return level;
  },

  /**
   *
   * @param {string} nameStr
   */
  validateUsername(nameStr) {
    return (nameStr.indexOf('@') > -1 ? this.emailReg.test(nameStr) : this.telReg.test(nameStr))
  },
  tickerCountDown(second, timeout, callback) {
    let s = second
    if (timeout === undefined) {
      timeout = 1000
    }
    let intervalId = setInterval(() => {
      s--
      if (s === 0) {
        clearInterval(intervalId)
        callback(-1)
      } else {
        callback(s)
      }
    }, 1000)
    return intervalId
  },
  GetRequest: function () {
    var url = location.search // 获取url中"?"符后的字串
    var theRequest = {}
    if (url.indexOf('?') != -1) {
      var str = url.substr(1)
      let strs = str.split('&')
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
      }
    }
    return theRequest
  },
  GetRequestUrl: function (url) {
    var theRequest = {}
    let strs = url.split('&')
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
    return theRequest
  },
  clearNoNum: function (val, num) {
    num = num === undefined ? 8 : num
    let newVal = val.toString()
    newVal = newVal.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    newVal = newVal.replace(/^\./g, '') // 验证第一个字符是数字而不是.
    newVal = newVal.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    newVal = newVal.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    newVal = newVal.replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{0,' + num + '}).*$', 'gmi'), '$1$2.$3')
    return newVal
  }
}

/**
 * 扩展原生Date对象，添加format方法
 * @param  {string} format [格式化类型]
 * @return {[type]}        [description]
 */
Date.prototype.format = function (format) {
  let o = {
    'M+': this.getMonth() + 1, // month
    'd+': this.getDate(), // day
    'h+': this.getHours(), // hour
    'H+': this.getHours(), // hour
    'm+': this.getMinutes(), // minute
    's+': this.getSeconds(), // second
    'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
    'S': this.getMilliseconds() // millisecond
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) if (new RegExp('(' + k + ')').test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return format
}

export default common
