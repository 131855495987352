<template>
    <!-- 列表中的loading占位 -->
    <div class="bg-table-loading-components" :class="{top:$attrs.top}" v-bind="$attrs" v-on="$listeners"  >
       <div class="loading" v-loading="flag"></div>
    </div>
</template>
<script>
export default {
    name:'bgTableLoading',
    data(){
       return {
         flag:true
       }
    }
}
</script>
<style lang="less" scoped>
    .bg-table-loading-components{
        height: 96%;
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        &.top{
            top:100px;
        }
        .loading{
            height: 100%;
            width: 100%;
        }

    }
</style>
