<template>
<!-- 搜索 时间选项 -->
    <bg-search-item label="">
          <el-select class="search-sel" size="small" readonly v-model="dateType" @change="selectDateType" >
            <el-option :label="$t('common.seven_day')" :value="1"></el-option>
            <el-option :label="$t('common.three_month')" :value="2"></el-option>
          </el-select>
          <el-date-picker class="search-rang" v-model="dateRangeStr" :clearable="false" @change="selectDate" size="small" type="daterange" start-placeholder="" format="yyyy-MM-dd" value-format="timestamp"  :editable="false" popper-class="my-rang-class" :picker-options="pickerOptions"></el-date-picker>
        </bg-search-item>
</template>
<script>
export default {
    name:'bgSearchTime',
    data(){
        return {
            //1 7 天 2 30天
            dateType:1,
            //时间范围
            dateRangeStr: '',
            //开始时间
            startTime:'',
            //结束时间
            endTime:'',
            // 时间控件配置
            pickerOptions: {
               disabledDate(time) {
                let t = Date.now();
                let ms = 24 * 60 * 60 * 1000;
                let min7 = t - 7 * ms;
                let min90 = t - 90 * ms;
                return time.getTime() > t || time.getTime() < min90;
                }
            },
        }
    },
    mounted(){
        // 设置默认时间区间
        this.setDataRange(7);
        let obj={
                dateType:this.dateType,
                startTime:this.startTime,
                endTime:this.endTime
            }
        this.$emit('initdate',obj)
    },
    methods:{
        setDataRange(limit){
            let te = Date.now();
            let rangems = 24 * 60 * 60 * 1000;
            let tb = te - limit * rangems;
            this.dateRangeStr = [tb, te];
            this.setTime(this.dateRangeStr );
        },
        setTime(range){
          this.startTime =Date.parse(
            new Date(range[0]).format("yyyy/MM/dd") + " 00:00:00"
          );
          this.endTime =Date.parse(
           new Date(range[1]).format("yyyy/MM/dd") + " 23:59:59"
          );
        },
        selectDate(val) {
           this.setTime(val);
           this.change();
        },
        selectDateType(val) {
            let day =7;
            if(val===2){
                day= 90 ;
            }
            this.setDataRange(day);
            this.change();
        },
        change(){
            let obj={
                dateType:this.dateType,
                startTime:this.startTime,
                endTime:this.endTime
            }
            this.$emit('change',obj);
        }
    }
}
</script>
<style scoped>
::v-deep .search-rang .el-icon-date:before {
  content: none !important;
}
::v-deep.record-search .search-rang .el-icon-date{
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(~assets/img/common/icon_rili.png);
}
</style>
