<template>
  <div class="gl-slider"
       role="slider"
       :class="[`gl-slider--${classWrapper}`, theme]"
  >
    <div class="gl-slider__runway"
         ref="slider"
         @click="onSliderClick" :class="{'disabled': disabled}">
      <div class="gl-slider__bar"></div>
      <slider-button
        ref="button"
        v-model="firstValue"
        @change="handleChange"
        :tooltip-class="tooltipClass">
      </slider-button>
      <div class="gl-slider__points" v-for="(item, index) in points" :style="{'left': item + '%'}"
           :key="'points' + index"
           :class="[(firstValue / (max - min)) * 100 > item ? ('gl-slider__points--active ' + (item > safe ? unsafeClass : '')) : '']">

          <div class="gl-slider__points_small"
           :class="[(firstValue / (max - min)) * 100 > item ? ('gl-slider__points_small--active ' + (item > safe ? unsafeClass : '')) : '']"></div>

          <!-- <div class="gl-slider__points_small"></div> -->
      </div>
      <div class="gl-slider__sub-runway" v-for="(runway, index) in subRunways" :key="'runway' + index"
           :class="runway.class"
           :style="{left: runway.min + '%', width: (runway.dynamic ? ((firstValue / (max - min)) * 100) - runway.min : (((firstValue / (max - min)) * 100) > runway.max ? runway.max : ((firstValue / (max - min)) * 100) - runway.min)) + '%', zIndex: 900 - index}"></div>
    </div>
    <div class="gl-slider__marker" v-if="showMarker">
      <span class="gl-slider__marker--left" ref="leftMarker"><slot name="leftMarker"></slot></span>
      <span class="gl-slider__marker--safe" ref="safeMarker" :style="safeMarkerPosition"><slot name="safeMarker"></slot></span>
      <span class="gl-slider__marker--right" ref="rightMarker"><slot name="rightMarker"></slot></span>
    </div>
  </div>
</template>

<script>
  import SliderButton from './button.vue';

  export default {
    name: 'GlSlider',
    components: {
      SliderButton,
    },
    props: {
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      step: {
        type: Number,
        default: 0.1,
      },
      isMagnetic: {
        type: Boolean,
        default: false,
      },
      break: {
        type: Number,
        default: 0,
      },
      value: {
        type: Number,
        default: 0,
      },
      points: {
        type: Array,
        default: () => [0, 100],
      },
      showPoints: {
        type: Boolean,
        default: true,
      },
      classWrapper: {
        type: String,
      },
      showTooltip: {
        type: Boolean,
        default: true,
      },
      tooltipClass: String,
      subRunways: {
        type: Array,
        default: () => [
          {
            class: 'gl-slide__default-sub-runway',
            min: 0,
            max: 100,
            dynamic: true,
          },
        ],
      },
      safe: {
        type: Number,
        default: 100,
      },
      unsafeClass: String,
      formatTooltip: Function,
      showMarker: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      theme: String,
    },
    data() {
      return {
        firstValue: 0,
        secondValue: null,
        oldValue: null,
        dragging: false,
        sliderSize: 1,
        safeMarkerPosition: {left: '50%'},
      };
    },
    watch: {
      value() {
        this.setValues();
      },
      firstValue(val) {
        this.$emit('input', val);
      },
      safe() {
        this.resetSize();
      },
      disabled: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.$emit('input', 0);
          }
        },
      },
    },
    computed: {
      precision() {
        let precisions = [this.min, this.max, this.step].map(item => {
          let decimal = ('' + item).split('.')[1];
          return decimal ? decimal.length : 0;
        });
        return Math.max.apply(null, precisions);
      },
      validRunways() {
        return this.subRunways.filter(runway =>
          runway.min < runway.max && runway.min >= this.min && runway.max <= this.max,
        );
      },
    },
    methods: {
      resetSize() {
        if (this.$refs.slider) {
          this.sliderSize = this.$refs.slider[`client${this.vertical ? 'Height' : 'Width'}`];
        }
      },
      onSliderClick(event) {
        if (this.disabled || this.dragging) return;
        this.resetSize();
        const sliderOffsetLeft = this.$refs.slider.getBoundingClientRect().left;
        this.setPosition((event.clientX - sliderOffsetLeft) / this.sliderSize * 100);
      },
      setPosition(percent) {
        this.$refs.button.setPosition(percent);
      },
      setValues() {
        if (this.min > this.max) {
          console.error('min should not be greater than max.');
          return;
        }
        const val = this.value;
        if (typeof val === 'number' && !isNaN(val)) {
          if (val < this.min) {
            this.$emit('input', this.min);
            this.firstValue = this.min;
          } else if (val > this.max) {
            this.$emit('input', this.max);
            this.firstValue = this.max;
          } else {
            this.firstValue = val;
            if (this.valueChanged()) {
              this.oldValue = val;
            }
          }
        }
      },
      valueChanged() {
        return this.value !== this.oldValue;
      },
      handleChange(e) {
        this.$emit('change', e);
      },
    },
    mounted() {
      if (typeof this.value !== 'number' || isNaN(this.value)) {
        this.firstValue = this.min;
      } else {
        this.firstValue = Math.min(this.max, Math.max(this.min, this.value));
      }
      this.oldValue = this.firstValue;

      this.resetSize();
      window.addEventListener('resize', this.resetSize);
    },
  };
</script>

<style lang="less" scoped>
  .gl-slider {
    width: 100%;
    padding: 0 9px;

    * {
      box-sizing: border-box;
    }

    /*&.white {
      .gl-slider__runway, .gl-slider__points {
          background-color: #D8D8D8;
      }
      .gl-slider__points--active {
          background-color: #ffffff;
      }
      .gl-slider__sub-runway {
          background-color: @gray-P1;
      }
      .gl-slider_points_small{
          background: #ffffff;
          &.gl-slider_points_small--active{
              background:@gray-P1;
          }
      }
    }

    &.black {
      .gl-slider__runway, .gl-slider__points {
          background-color: #3A3D4B;
      }
      .gl-slider_points_small{
          background: #1c1c1c !important;
          &.gl-slider_points_small--active{
              background:#ffffff;
          }
      }
      .gl-slider__button{
          background:#ffffff
      }
    }*/
  }

  .gl-slider__runway {
    width: 100%;
    height: 4px;
    margin: 12px 0;
    background-color: var(--vice-deep-blue);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    &.disabled {
      .gl-slider__bar {
        background-color: var(--vice-deep-blue);
      }

      .gl-slider__button {
        border-color: var(--vice-deep-blue) !important;
        background-color: var(--vice-deep-blue) !important;
      }

      .gl-slider__button-wrapper {
        &:hover,
        &.hover {
          cursor: not-allowed;
        }

        &.dragging {
          cursor: not-allowed;
        }
      }

      .gl-slider__button {
        &:hover,
        &.hover,
        &.dragging {
          transform: scale(1);
        }

        &:hover,
        &.hover {
          cursor: not-allowed;
        }

        &.dragging {
          cursor: not-allowed;
        }
      }
    }
  }

  .gl-slider__bar {
    height: 4px;
    background-color: #409EFF;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    position: absolute;
  }

  .gl-slider__points {
    position: absolute;
    height: 6px !important;
    width: 6px !important;
    top: -10px;
    z-index: 901;
    line-height: 100px;
    background: #EAECEF;
    text-align: center;
    color: @text-white;
    clip-path: inset(0% 0% 0% 0% round 1.5px);
    &.gl-slider__points--active {
      background: @theme-white;
    }
  }

  .gl-slider__points_small{
    height: 4px;
    width: 4px;
    position: absolute;
    top: -3px;
    left: 1px;
    text-align: center;
    background: @theme-black !important;
    clip-path: inset(0% 0% 0% 0% round 0.5px);
    margin: 4px auto;

    &.gl-slider__points_small--active {
      background: @theme-black;
    }
  }
  &.white {
    .gl-slider__points_small{
      background: @theme-white !important;
      &.gl-slider__points_small--active{
        background:@theme-white !important;
      }
    }
  }

  .gl-slider__sub-runway {
    position: absolute;
    height: 4px;
    border-radius: 3px;
    background: @gray-P1;
  }

  .gl-slider__marker {
    position: relative;
    line-height: 12px;
  }

  .gl-slider__marker--left, .gl-slider__marker--right, .gl-slider__marker--safe {
    font-size: 12px;
    color: #697281;
    line-height: 12px;
    user-select: none;

    .medium-font();
  }

  .gl-slider__marker--left {
    text-align: left;
    position: absolute;
    left: -9px;
  }

  .gl-slider__marker--right {
    text-align: right;
    position: absolute;
    right: -9px;
  }

  .gl-slider__marker--safe {
    text-align: center;
    position: absolute;
    transform: translateX(-50%);
  }
</style>
