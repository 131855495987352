<template>
   <!-- 资产方面的大title -->
  <div class="bg-title-component" :class="{'block':$attrs.block}">
    <div class="common-title" :class="{'minor':$attrs.size == 'minor'}" >
        <slot></slot>
   </div>
  </div>
</template>

<script>
export default {
  name: "bgTitle",
};
</script>

<style lang="less">
.bg-title-component{
    display: inline-block;
    &.block{
      display:block;
    }
   .common-title {
        display: inline-block;
        font-size: 26px;
        color: @text-black;
        line-height: 30px;
        font-weight: bold;
        display: flex;
        align-items: center;
        .medium-font();
        &.minor{
          font-size:20px;
          line-height: 20px;
        }
        .icon-fanhui {
          padding-right: 10px;
          color: #697281;
          font-size: 26px;
        }
        .icon-dialog-tishi{
          padding-right: 10px;
          width: 20px;
          height: 26px;
          background: url('~assets/img/account/icon-dialog-tishi.svg') center center no-repeat;

        }
    }

}

</style>
