<template>

  <div class="record-search">
    <label>{{label}}</label>
    <div class="record-search-input">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "bgSearchItem",
  props: {
    label: String,
  },
};
</script>

<style lang="less">
.record-search{
  display: inline-block;
  margin-right: 16px;
  >label{
    display: block;
    font-size: 12px;
    text-align: left;
    color: #9EA7B7;
  }
  .record-search-input{
    margin-top: 8px;
  }
      // 普通选择框
     .search-sel{
      width: 155px;
    }
    //时间选择框
    .search-rang{
      position: relative;
      top: 1px;
      width: 204px;
      margin-left: 14px;
      .el-icon-date{
        position: relative;
        left: 96%;
      }
      .el-range-input{
        width: 100%;
        position: relative;
        left: -10px;
      }
      .el-range-separator{
        position: relative;
        left: -6%;
      }
    }
}
</style>
