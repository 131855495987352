<template>
    <span :class="{'green':Number(value)>0,'red':Number(value)<0}" >{{value}}</span>
</template>
<script>
export default {
    name:'bgColorText',
    data(){
        return {

        }
    },
    props:{
        value:{
            type:[String,Number],
            default:0
        }
    }
}
</script>