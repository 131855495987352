<template>
<!-- 二次封装 el-dialog 组件 -->
 <div class="bg-dialog-component">
    <el-dialog :visible.sync="myShow" v-bind="$attrs" v-on="$listeners" >
        <slot></slot>
    </el-dialog>
 </div>
</template>
<script>
export default {
    name:'bgDialog',
    data(){
       return {}
    },
    props:{
        visible:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        myShow:{
            get(){
                return this.visible;
            },
            set(val){
                this.$emit('update:visible',val);
            }
        }
    },
}
</script>
<style lang="less">
  .bg-dialog-component{

  }
</style>