<template>
    <div class="bg-eye-component" :class="[newTheme]">
      <div v-if="value" class="iconfont iconImg eyeopen" @click="toggle"></div>
      <div v-else class="iconfont iconImg eyeclose" @click="toggle"></div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    name:'bgEye',
    data(){
        return {
          ...mapState(['newTheme'])
        }
    },
    props:{
        value:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        toggle(){
         this.$emit('input',!this.value);
         this.$emit('change',!this.value);
        }
    }
}
</script>
<style lang="less">
  .bg-eye-component{
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      i{
        font-size: 18px;
        color:  @text-minor-color;
        cursor: pointer;
      }
      .iconImg{
          height: 22px;
          width: 22px;
            &.eyeopen{
                height: 20px;
                width: 20px;
                // background-image: url(~assets/img/new_index/icon_eye_open.svg);
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: center;

            }
            &.eyeclose{
                height: 20px;
                width: 20px;
                // background-image: url(~assets/img/new_index/icon_eye_close.svg);
                 background-size: 100% auto  ;
                background-repeat: no-repeat;
                background-position: center;
            }
      }

  }
  .white .eyeopen{
      background-image: url(~assets/img/new_index/icon_eye_open_white.svg);
  }
  .white .eyeclose{
      background-image: url(~assets/img/new_index/icon_eye_close_white.svg);
  }
  .black .eyeopen{
      background-image: url(~assets/img/new_index/icon_eye_open_black.svg);
  }
  .black .eyeclose{
      background-image: url(~assets/img/new_index/icon_eye_close_black.svg);
  }

</style>
