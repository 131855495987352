<template>
<div class="bg-check-components">
  <span class="check-style" :class="{active: myChecked, 'disabled': disabled}" @click="setChecked"></span>
  <div class="check-text" @click="setChecked">
    <slot></slot>
  </div>
</div>

</template>

<script>

export default {
  name:'bgCheckBox',
  props: ['checked', 'disabled'],
  data() {
    return {
    }
  },
  computed:{
    myChecked:{
      get(){
        return this.checked;
      },
      set(val){
        this.$emit('update:checked', val);
      }
    }
  },
  methods: {
    setChecked() {
      if(this.disabled) return
      this.myChecked = !this.myChecked;
    }
  }
}
</script>

<style lang="less">

.bg-check-components{
   display:inline-flex;
   align-items: center;
  .check-style{
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-image: url(~assets/img/common/checkbox.svg);
    background-color: transparent;
    border: none;
    z-index: 1;
    cursor: pointer;
    margin-right: 4px;
    &.active{
      background-image: url(~assets/img/common/checkbox_active.svg);
 	    background-color: transparent;
      border: none;
    }

    &.disabled {
      cursor: not-allowed;
     /*  background-color: #e1e3e5;
      border-color: #e1e3e5; */
    }
  }

  .check-text{
    cursor: pointer;
    color:  @gray-P1;
  }
}

</style>
