<template>
    <!-- 币种 -->
      <bg-search-item label="">
        <el-select class="search-sel" key="1" v-bind="$attrs" :placeholder="$t('lever.placeholder_all_coin')" size="small" readonly filterable v-model="coinId" @change="changeHandle">
          <el-option v-for="(item, index) in allCoinList" :key="index" :label="item.coinName" :value="item.coinId"></el-option>
        </el-select>
      </bg-search-item>
</template>
<script>
import {  mapGetters } from 'vuex'
export default {
    name:'bgSearchCoin',
    props:{
      value:{
          type:[Number,String],
          default:''
      },
      // filterable: {
      //   type: Boolean,
      //   default: false,
      // },
    },
    data(){
        return {
          coinList:[],
        }
    },
    computed:{
      ...mapGetters(['searchAllCoinConfigList']),
      allCoinList(){
        let list = this.searchAllCoinConfigList.slice();
          list.unshift({
                coinId: '',
                coinName: this.$t("lever.placeholder_all_coin")
          });
          return list;
     },
      coinId:{
          get(){
              return this.value;
          },
          set(val){
            this.$emit('input',val);
          }
      }
    },
    mounted(){
      //this.coinId = -1;
    },
    methods:{
      changeHandle(val){
        this.coinId=val;
        this.$emit('change',val);
      }
    }
}
</script>
<style lang="less">

.el-select .el-input .el-select__caret{
  transform: rotateZ(0);
  // height: 20px;
  // width: 6px;
  // background: url('~assets/img/public/icon-arrow.png') center center no-repeat;
  // background-size: 30% 20%;
    &::before{
      content: '\e790';
    }
  &.is-reverse{
    transform: rotateZ(180deg);
  }
  .el-icon-arrow-up{
    &::before{
        content: '\e790';
      }
  }
}
</style>
