<template>
  <div class="bg-sh-components">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:'bgScrollHorizontally',
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.bg-sh-components{}
</style>
