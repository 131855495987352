<template>
  <button class="bg-button white" :class="[type]" @click="handleClick" :disabled="buttonDisabled" v-bind="$attrs">
    <i class="el-icon-loading" v-if="loading"></i>
    <i :class="icon" v-if="icon && !loading"></i>
    <span class="bg-button-text" v-if="$slots.default"><slot></slot></span>
  </button>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  name: "bgButton",
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default() {
        return this.$t('common.btn_ok');
      }
    },
    type: {
      type: String,
      default: 'default',
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['default', 'plain'].indexOf(value) !== -1
      },
    },
    loading: Boolean,
    disabled: Boolean,
    icon: String,
    href: String,
  },
  computed: {
    // ...mapState(['theme']),
    buttonDisabled() {
      return this.disabled || this.loading;
    }
  },
  methods: {
    handleClick(evt) {
      if(this.href) {
        BT.util.openTab(href);
      }
      this.$emit('click', evt);
    }
  },
}
</script>

<style lang="less">
  .bg-button{
    position: relative;
    display: inline-block;
    height: 40px;
    margin: 0;
    padding: 12px 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    outline: none;

    &.white{
      --main-btn-bgc: #357AFF;
      --plain-color: #697281;
      --hover-color: rgba(0, 75, 221, 0.5);
      --disabled-color: #8891A0;
      --disabled-text-color: #000;
    }
    &.black{
      --main-btn-bgc: #1788E0;
      --plain-color: B5BECE;
      --hover-color: rgba(0, 104, 213, 0.5);
      --disabled-color: #3A404F;
      --disabled-text-color: #fff;
    }

    background-color: var(--main-btn-bgc);
    border: 1px solid var(--main-btn-bgc);
    border-radius: 20px;
    transition: .15s;

    &:hover{
      background-color: var(--hover-color);
      border: 1px solid var(--hover-color);
    }

    &.default{
      color: #fff;
    }
    &.plain{
      color: var(--plain-color);
      border: 1px solid #E2E2E2;
      background-color: transparent;
    }

    &:disabled{
      color: var(--disabled-text-color);
      background-color: var(--disabled-color);
      border: 1px solid var(--disabled-color);
      cursor: not-allowed;
    }

    .bg-button-text{
      position: relative;
      z-index: 2;
    }
  }
</style>
