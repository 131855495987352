<template>
  <div :class="['gl-slider__button-wrapper', disabled ? 'disabled' : '']"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
       @mousedown="onButtonDown"
       @touchstart="onButtonDown"
       :style="wrapperStyle"
       @focus="handleMouseEnter"
       @blur="handleMouseLeave"
  >
    <!--<div class="gl-slider__button"></div>-->
    <el-tooltip
      placement="top"
      ref="tooltip"
      :popper-class="tooltipClass"
      :disabled="!showTooltip || disabled">
      <span slot="content">{{formatValue}}</span>
      <div class="gl-slider__button"
           :class="{ 'hover': hovering, 'dragging': dragging, 'gl-slider__button--borrow': safe }"></div>
    </el-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'GlSliderButton',
    props: {
      value: {
        type: Number,
        default: 0,
      },
      tooltipClass: String,
    },
    computed: {
      disabled() {
        return this.$parent.disabled;
      },
      currentPosition() {
        const value = this.value > this.max ? this.max : this.value;
        return `${(value - this.min) / (this.max - this.min) * 100}%`;
      },
      wrapperStyle() {
        return {left: this.currentPosition};
      },
      max() {
        return this.$parent.max;
      },

      min() {
        return this.$parent.min;
      },

      step() {
        return this.$parent.step;
      },
      precision() {
        return this.$parent.precision;
      },
      showTooltip() {
        return this.$parent.showTooltip;
      },
      safe() {
        let parentSafe = this.$parent.safe;
        if (!isNaN(parentSafe)) {
          if (Number(parentSafe) <= 0 && this.value !== 0) {
            return true;
          } else {
            return (this.value - parentSafe > 0);
          }
        } else {
          return false;
        }
      },
      enableFormat() {
        return this.$parent.formatTooltip instanceof Function;
      },

      formatValue() {
        return this.enableFormat && this.$parent.formatTooltip(this.value) || this.value;
      },
    },

    data() {
      return {
        hovering: false,
        dragging: false,
        isClick: false,
        startX: 0,
        currentX: 0,
        startY: 0,
        currentY: 0,
        startPosition: 0,
        newPosition: null,
        oldValue: this.value,
      };
    },
    methods: {
      handleMouseEnter() {
        this.hovering = true;
        this.displayTooltip();
      },
      handleMouseLeave() {
        this.hovering = false;
        this.hideTooltip();
      },
      displayTooltip() {
        this.$refs.tooltip && (this.$refs.tooltip.showPopper = true);
      },

      hideTooltip() {
        this.$refs.tooltip && (this.$refs.tooltip.showPopper = false);
      },
      onButtonDown(event) {
        if (this.disabled) return;
        event.preventDefault();
        this.onDragStart(event);
        window.addEventListener('mousemove', this.onDragging);
        window.addEventListener('touchmove', this.onDragging);
        window.addEventListener('mouseup', this.onDragEnd);
        window.addEventListener('touchend', this.onDragEnd);
        window.addEventListener('contextmenu', this.onDragEnd);
      },
      onDragStart(event) {
        this.dragging = true;
        this.isClick = true;
        if (event.type === 'touchstart') {
          event.clientY = event.touches[0].clientY;
          event.clientX = event.touches[0].clientX;
        }
        this.startX = event.clientX;
        this.startPosition = parseFloat(this.currentPosition);
        this.newPosition = this.startPosition;
      },
      onDragging(event) {
        if (this.dragging) {
          this.isClick = false;
          this.displayTooltip();
          this.$parent.resetSize();
          let diff = 0;
          if (event.type === 'touchmove') {
            event.clientY = event.touches[0].clientY;
            event.clientX = event.touches[0].clientX;
          }
          this.currentX = event.clientX;
          diff = (this.currentX - this.startX) / this.$parent.sliderSize * 100;
          this.newPosition = this.startPosition + diff;
          this.setPosition(this.newPosition);
        }
      },

      onDragEnd() {
        if (this.dragging) {
          /*
           * 防止在 mouseup 后立即触发 click，导致滑块有几率产生一小段位移
           * 不使用 preventDefault 是因为 mouseup 和 click 没有注册在同一个 DOM 上
           */
          setTimeout(() => {
            this.dragging = false;
            this.hideTooltip();
            if (!this.isClick) {
              this.setPosition(this.newPosition);
            }
          }, 0);
          window.removeEventListener('mousemove', this.onDragging);
          window.removeEventListener('touchmove', this.onDragging);
          window.removeEventListener('mouseup', this.onDragEnd);
          window.removeEventListener('touchend', this.onDragEnd);
          window.removeEventListener('contextmenu', this.onDragEnd);
        }
      },
      setPosition(newPosition) {
        if (newPosition === null) return;
        if (newPosition < 0) {
          newPosition = 0;
        } else if (newPosition > 100) {
          newPosition = 100;
        }
        const lengthPerStep = 100 / ((this.max - this.min) / this.step);
        const steps = Math.round(newPosition / lengthPerStep);
        let value = steps * lengthPerStep * (this.max - this.min) * 0.01 + this.min;
        value = parseFloat(value.toFixed(this.precision));
        if (Math.abs(value - this.break) <= 0.5) {
          value = this.break;
        }
        this.$emit('input', value);
        this.$emit('change', value);
        this.$nextTick(() => {
          this.$refs.tooltip && this.$refs.tooltip.updatePopper();
        });
        if (!this.dragging && this.value !== this.oldValue) {
          this.oldValue = this.value;
        }
      },
    },
  };
</script>


<style lang="less" scoped>
  .gl-slider__button-wrapper {
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 1001;
    top: -3px;
    transform: translateX(-50%);
    background: @yellow-P3;
    text-align: center;
    user-select: none;
    line-height: normal;
    clip-path: inset(0% 0% 0% 0% round 2px);
    transform: rotate(45deg);
    .el-tooltip {
      vertical-align: middle;
      display: inline-block;
    }

    &:hover,
    &.hover {
      cursor: grab;
    }

    &.dragging {
      cursor: grabbing;
    }

    &:after {
      display: inline-block;
      content: "";
      height: 100%;
      vertical-align: middle;
    }

    &.disabled {
      .gl-slider__button {
        border-color: var(--vice-deep-blue) !important;
        background: @yellow-P3 !important;
        cursor: not-allowed;
      }
    }
  }

  .gl-slider__button {
    width: 14px;
    height: 14px;
    background: #fff;
    transition: .2s;
    user-select: none;
    display: inline-block;
    vertical-align: middle;
    clip-path: inset(0% 0% 0% 0% round 0.5px);

    &:hover,
    &.hover,
    &.dragging {
      background: @gray-P1;
      transform: scale(1.2);
    }

    &:hover,
    &.hover {
      cursor: grab;
    }

    &.dragging {
      cursor: grabbing;
    }

    &:after {
      display: inline-block;
      content: "";
      height: 100%;
      vertical-align: middle;
    }
  }
</style>
